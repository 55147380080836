<template>
  <div class="container nav z-900 w-full flex-1 flex items-center justify-end">
    <nav v-if="dataRender && dataRender.length > 0" class="">
      <!-- <a href="#" class="mobile-menu-trigger">Open mobile menu</a> -->
      <ul ref="header" class="menu menu-bar activeMenu">
        <li
            v-for="(item, index) in dataRender"
            :key="item?.id"
            class="flex items-center hover:text-primary text-bw-07"
        >
          <nuxt-link
              v-if="item?.url.startsWith('http')"
              :to="item?.url ?? '/'"
              target="_blank"
              :class="item?.childs && item?.childs.length > 0 ? 'hasChild' : ''"
              class="menu-link py-8 font-semibold lg:text-sm xl:text-base flex items-center gap-0.5"
          >
            <p>{{ item?.name }}</p>
            <span
                :class="item.childs && item?.childs.length > 0 ? 'block' : 'hidden'"
                class="i-ic:twotone-keyboard-arrow-down text-lg"
            ></span>
          </nuxt-link>
          <nuxt-link
              v-else
              :to="item?.url ?? '/'"
              :class="item?.childs && item?.childs.length > 0 ? 'hasChild' : ''"
              class="menu-link py-8 font-semibold lg:text-sm xl:text-base flex items-center gap-0.5"
          >
            <p>{{ item?.name }}</p>
            <span
                :class="item.childs && item?.childs.length > 0 ? 'block' : 'hidden'"
                class="i-ic:twotone-keyboard-arrow-down text-lg"
            ></span>
          </nuxt-link>
          <ul
              v-if="item.mega"
              class="hidden lg:flex justify-center mega-menu lg:px-10 xl:px-18 py-14 shadow-md lg:b-1 lg:b-solid lg:bt-bw-08"
          >
            <div class="item-left-mega pr-5 w-3/12 b-r-2 max-w-100">
              <MenuItemLeft/>
            </div>

            <ul
                v-if="item?.childs && item?.childs.length > 0"
                class="flex-1 relative mega-menu--multiLevel lg:px-3 w-6/12 max-w-1400px mt-11"
            >
              <p
                  class="absolute top-0 left-6 text-black transform -translate-y-11 lg:text-xl xl:text-2xl leading-5 lg:leading-10 font-[550] line-clamp-1"
              >
                {{ $t('INSURNACE_PRODUCT_INFO') }}
              </p>
              <li
                  v-for="(itemChild, index) in item?.childs"
                  :key="index"
                  class="lg:flex text-lg text-black/40"
              >
                <MenuItemMenu :item="itemChild"></MenuItemMenu>
              </li>
            </ul>
            <div class="item-right-mega pl-5 xl:pl-10 w-4/12 b-l-2 max-w-100">
              <MenuItemRight/>
            </div>
          </ul>
          <ul
              v-if="item?.childs && item?.childs.length > 0"
              class="lg:px-5 lg:py-3 absolute top-full bg-white flex flex-col gap-3 b-1 b-solid b-bw-08 shadow-md rounded-b-md"
          >
            <li
                v-for="(itemChild, index) in item?.childs"
                :key="index"
                class="lg:flex text-lg text-black/40 block"
            >
              <component
                  :is="linkItem(itemChild.url)"
                  :to="itemChild.url || '/'"
                  class="font-semibold text-black/40 hover:text-primary lg:text-sm xl:text-base"
                  :class="itemChild.childs && itemChild.childs.length > 0 ? 'hasChild' : ''"
              >
                {{ itemChild?.name }}
              </component>
              <span
                  :class="
                                    itemChild?.childs && itemChild?.childs.length > 0 ? 'hidden lg:block' : 'hidden'
                                "
                  class="flex-none i-ic:twotone-keyboard-arrow-right mt-1 text-15px"
              ></span>
            </li>
            <li class="mobile-menu-back-item">
              <a href="" class="menu-link mobile-menu-back-link">Back</a>
            </li>
          </ul>
        </li>
        <!-- <li class="mobile-menu-header">
            <a href="/home" class="">
                <span>Home</span>
            </a>
        </li> -->
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
import {useAppGlobalData} from '../../composables'

const lang = useRoute().params.langCode
const {getDataMenuHeader} = useAppGlobalData()
const dataRender = computed(() => {
  if (lang) {
    return getDataMenuHeader(useRoute().params.langCode)
  }
  return
})
const linkItem = (item: any) => {
  if (item.url?.includes('http')) return 'a'
  return resolveComponent('NuxtLink')
}
const currentRoute = useRoute()
const header = ref()
watch(
    () => currentRoute.path,
    (newValue: string) => {
      if (newValue) {
        setTimeout(() => {
          header.value.classList.remove('activeMenu')
        }, 200)
        setTimeout(() => {
          header.value.classList.add('activeMenu')
        }, 500)
      }
    }
)
</script>

<style>
nav ul,
nav li {
  list-style: none;
  margin: 0;
}

nav a {
  display: block;
  text-decoration: none;
  line-height: 24px;
}

.menu-bar {
  background: #ffffff;
  display: flex;
  z-index: 1000;
  @apply lg:gap-5 xl:gap-8;
}

.menu-link {
  background: #ffffff;
  transition: background 0.2s, color 0.2s;
  position: relative;
  z-index: 1;
}

.mega-menu-header {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: bold;
}

.mega-menu {
  background: #ffffff;
  z-index: 10;
}

.mega-menu--multiLevel ul {
  flex-direction: column;
}

@media all and (min-width: 951px) {
  .nav {
  }

  .menu.menu-list {
    @apply b-l-2 b-gray-100;
  }

  .menu .hasChild ~ ul {
    display: none;
  }

  .menu-bar > li a + ul {
    display: none;
  }

  .menu-bar.activeMenu > li:hover > .hasChild ~ ul {
    display: flex;
    transform-origin: top;
    animation: dropdown 0.25s cubic-bezier(0.57, 0.21, 0.69, 0.8);
  }

  /*
   .menu-bar > li:hover >  ul {
      display: flex;
      transform-origin: top;
      animation: dropdown 0.2s ease-out;
  }
  .menu-bar > li:hover > .hasChild ~ ul {
      display: flex;
  }
  */
  .menu-bar > li > .hasChild:hover,
  .menu-bar > li:hover > .hasChild,
  .menu-bar > li:hover > a {
  }

  .menu-bar > li > .hasChild:hover:after,
  .menu-bar > li:hover > .hasChild:after,
  .mega-menu {
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
  }

  .mega-menu::after {
    content: '';

    @apply absolute z-1 inset-0 w-full h-10 bg-transparent z-100 transform -translate-y-7;
  }

  .mega-menu:hover {
    display: flex;
  }

  .mega-menu--multiLevel li:hover > .hasChild,
  .mega-menu--multiLevel li:hover > span,
  .mega-menu--multiLevel li.activeMenu > .hasChild,
  .mega-menu--multiLevel li.activeMenu > span {
    @apply text-primary bg-gray-100 rounded-lg;
  }

  .mega-menu--multiLevel > li > a {
    max-width: 50%;
  }

  .mega-menu--multiLevel > li {
    width: 100%;
  }

  .mega-menu--multiLevel > li > .hasChild + ul {
    left: 53%;
    width: 43%;
  }

  .mega-menu--multiLevel > li > .hasChild ~ ul ul {
    width: 100%;
    left: 100%;
    padding-left: 20px;
  }

  .mega-menu--multiLevel li:hover > .hasChild ~ ul {
    display: block;
    transform-origin: left;
    animation: flyout 0.2s ease-out;
  }

  .mega-menu--multiLevel li:hover > .hasChild ~ ul {
    display: block;
  }

  .mega-menu--multiLevel li.activeMenu > .hasChild ~ ul {
    display: block;
    transform-origin: left;
    animation: flyout 0.2s ease-out;
  }

  .mega-menu--multiLevel li.activeMenu > .hasChild ~ ul {
    display: block;
  }

  .mega-menu--multiLevel li:hover > .hasChild,
  .mega-menu--multiLevel li:hover > .hasChild,
  .mega-menu--multiLevel li:hover > a,
  .mega-menu--multiLevel li:hover > a {
  }

  .mega-menu--multiLevel .hasChild ~ ul,
  .mega-menu--multiLevel .hasChild {
  }

  .mega-menu--multiLevel .hasChild ~ ul:hover {
    display: block;
  }

  .mega-menu--multiLevel .hasChild ~ ul {
    position: absolute;
    top: 0;
    height: 100%;
  }

  .mega-menu--flat > * {
    flex: 1;
  }

  .mobile-menu-trigger,
  .mobile-menu-header,
  .mobile-menu-back-item {
    display: none;
  }

  .menu-link.hasChild + .menu-list {
  }

  .mega-menu.mega-menu--multiLevel a {
    @apply py-2;
  }

  .mega-menu--multiLevel {
    @apply relative;
  }

  .mega-menu li > a {
    @apply py-2 lg:text-sm  xl:text-base;
  }
}

@media all and (max-width: 950px) {
  .nav {
    padding: 20px;
  }

  .mobile-menu-trigger,
  .mobile-menu-header,
  .mobile-menu-back-item {
    display: block;
  }

  .mobile-menu-trigger {
    background: #177e89;
    color: #ffffff;
    border: 0;
    padding: 10px;
    font-size: 1.2em;
    border-radius: 4px;
  }

  .mobile-menu-header {
    order: -1;
    background: grey;
  }

  .mobile-menu-header a {
    padding: 20px 25px;
    color: #ffffff;
    visibility: visible;
  }

  .menu-bar {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    transition: left 0.3s;
    box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.25);
    @apply px-5;
  }

  .menu-bar > li > .hasChild ~ ul {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    position: absolute;
    left: 100%;
    top: 0;
    max-height: 100vh;
    width: 100%;
    transition: left 0.3s;
  }

  .menu-bar > li > .hasChild ~ ul > li > .hasChild {
    font-size: 1.2em;
  }

  .menu-bar > li > .hasChild ~ ul > li > .hasChild ~ ul a {
    padding-left: 40px;
  }

  .menu-bar > li > .hasChild ~ ul > li > .hasChild ~ ul > li > .hasChild ~ ul a {
    padding-left: 80px;
  }

  .menu-bar > li > .hasChild ~ ul .hasChild {
    color: #2a2a2a;
  }

  .menu-bar > li > .hasChild ~ ul .hasChild:after {
    content: '+';
    background: none;
    position: absolute;
    right: 20px;
    font-size: 1em;
    font-weight: normal;
    transform: scale(1.2);
    line-height: 1;
  }

  .menu-bar > li > .hasChild ~ ul .hasChild ~ ul {
    max-height: 0px;
    transform-origin: top;
    transform: scaleY(0);
    transition: max-height 0.1s;
  }

  .mega-menu-content {
    padding: 20px 25px;
  }

  .mobile-menu-back-item {
    order: -1;
  }

  .mobile-menu-back-item a {
    background: #d9d9d9;
    color: #2a2a2a;
    pointer-events: none;
  }

  .mobile-menu-back-item a:before {
    content: '';
    width: 14px;
    height: 12px;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowLeft.svg#default');
    background-size: 14px;
    margin-right: 10px;
    display: inline-block;
  }

  .mobile-menu-trigger:focus ~ ul {
    left: 0;
  }

  .menu-bar:hover,
  .menu-bar:focus-within {
    left: 0;
  }

  .menu-bar > li > .hasChild:focus ~ ul {
    left: 0;
  }

  .menu-bar > li > .hasChild ~ ul {
  }

  .menu-bar > li > .hasChild ~ ul:hover,
  .menu-bar > li > .hasChild ~ ul:focus-within {
    left: 0;
  }

  .menu-bar > li > .hasChild ~ ul .hasChild:focus ~ ul {
    max-height: 500px;
    animation: dropdown 0.3s forwards;
  }

  .menu-bar > li > .hasChild ~ ul li:focus-within > .hasChild ~ ul {
    max-height: 500px;
    transform: scaleY(1);
  }

  .menu-bar > li:focus-within ~ .mobile-menu-header a {
    visibility: hidden;
  }

  .menu-link {
    @apply w-full;
  }

  .menu-link.mega-menu-link {
    @apply px-5 py-3;
  }
}

@media all and (max-width: 950px) and (hover: none) {
  .mobile-menu-trigger:hover ~ ul {
    left: 0;
  }

  .menu-bar > li > .hasChild:hover ~ ul {
    left: 0;
  }

  .menu-bar > li > .hasChild ~ ul:hover {
    left: 0;
  }

  .menu-bar > li > .hasChild ~ ul .hasChild:hover ~ ul {
    max-height: 500px;
    animation: dropdown 0.3s forwards;
  }

  .menu-bar > li > .hasChild ~ ul .hasChild ~ ul:hover {
    max-height: 500px;
    transform: scaleY(1);
  }

  .menu-bar > li:hover ~ .mobile-menu-header a {
    visibility: hidden;
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes flyout {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

.main-menu-mobile-ul {
  @apply divide-y;
}

.main-menu-mobile > div {
  @apply container;
}

.item-menu-mobile p,
.item-menu-mobile span {
  @apply text-16px;
}

.item-menu-mobile .icon-drop-down {
  @apply transform -rotate-90;
}

.item-menu-mobile .icon-come-back {
  @apply transform rotate-360 text-30px text-primary;
}

.item-menu-mobile:hover {
  @apply !bg-bw-08;
}

.item-menu-mobile:hover a,
.item-menu-mobile:hover span {
  @apply !text-primary;
}

.menu.menu-bar > li > a.router-link-active,
.menu.menu-bar > li > a.router-link-active ~ span {
  @apply text-primary;
}

.main-menu-mobile {
  @apply p-0;
}

.main-menu-mobile ul {
  @apply divide-y;
}

@media (max-width: 1279px) {
  .menu-link {
    @apply text-xs;
  }

  .mega-menu li > a {
    @apply pb-3 text-xs;
  }

  .mega-menu li span {
    @apply mt-0;
  }
}
</style>
